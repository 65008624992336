import styled from '@emotion/styled';
import DOMPurify from 'dompurify';
import useLocalStorage from 'hooks/useLocalStorage';
import React, { forwardRef, Ref, useState } from 'react';
import RedirectDialog from 'ui/modules/dialogs/RedirectInterceptDialog';
import { isInternalUrl } from 'util/stringUtils';

interface Props {
  children: string;
  style?: object;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  embeddingIsAllowed?: boolean;
  externalLinkInterceptionIsDisabled?: boolean;
}

const Div = styled.div`
  white-space: pre-line;
  word-break: break-word;
`;

const defaultAllowedTags = {
  ALLOWED_TAGS: ['a', 'img', 'ul', 'li', 'ol', 'em', 'strong', 'p', 'span', 'br'],
  ALLOWED_ATTR: ['src', 'alt', 'rel', 'width', 'height', 'href', 'class', 'title', 'target'],
};

function HtmlContent(
  { children, style, onClick, className, embeddingIsAllowed, externalLinkInterceptionIsDisabled }: Props,
  ref: Ref<HTMLDivElement>,
) {
  const { host } = window.location;
  const [externalRedirectHref, setExternalRedirectHref] = useState<string | undefined>();
  const [hasOptedOutOfExternalLinkInterception, setHasOptedOutOfExternalLinkInterception] = useLocalStorage(
    `has-opted-out-of-external-link-interception`,
    false,
  );
  const [checkboxState, setCheckboxState] = useState(hasOptedOutOfExternalLinkInterception);

  const cleaned = DOMPurify.sanitize(children, {
    ALLOWED_TAGS: [...defaultAllowedTags.ALLOWED_TAGS, ...(embeddingIsAllowed ? ['iframe', 'div'] : [])],
    ALLOWED_ATTR: defaultAllowedTags.ALLOWED_ATTR,
  });
  return (
    <>
      <Div
        ref={ref}
        onClick={e => {
          onClick && onClick(e);
          if (e.target instanceof HTMLAnchorElement) {
            const href = e.target.getAttribute('href');
            if (
              href &&
              !isInternalUrl(host, href) &&
              !hasOptedOutOfExternalLinkInterception &&
              !externalLinkInterceptionIsDisabled
            ) {
              e.preventDefault();
              setExternalRedirectHref(href);
            }
          }
        }}
        style={style}
        className={className}
        dangerouslySetInnerHTML={{
          __html: cleaned,
        }}
      ></Div>
      {externalRedirectHref && (
        <RedirectDialog
          externalRedirectHref={externalRedirectHref}
          setExternalRedirectHref={setExternalRedirectHref}
          checkboxState={checkboxState}
          setCheckboxState={setCheckboxState}
          setHasOptedOutOfExternalLinkInterception={setHasOptedOutOfExternalLinkInterception}
        />
      )}
    </>
  );
}

export default forwardRef(HtmlContent);
